import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';
import { localStorageRemoveItem, localStorageSetItem } from 'utils/localStorageAccessor';
import { isNewLoginSystemEnabled } from 'Auth/Utilities/helper';

import { CustomStorage, CUSTOM_STORAGE_VAULT } from './customStorage';

const CUSTOM_ORG_ID_ATTRIBUTE = 'custom:OrgId';
const PHONE_NUMBER = 'phone_number';

export const ACCESS_TOKEN_KEY = 'ep_access_token';
export const ID_TOKEN_KEY = 'ep_id_token';

export const SSO_USER_INFO_KEY = 'SSO_USER_INFO';

const OVERRIDE_MTEP_SIGN_ON_ENABLED_FLAG_KEY = 'overrideIsMTEPSignInSignUpEnabled';

type CognitoUserWithAttributes = CognitoUser & { attributes: Record<string, unknown> };

enum WindowListeningEvent {
  REQUESTING_SHARED_CREDENTIALS = 'REQUESTING_SHARED_CREDENTIALS',
  CREDENTIALS_SHARING = 'CREDENTIALS_SHARING',
  FINISHED_SHARING_CREDENTIALS = 'FINISHED_SHARING_CREDENTIALS',
  CLEAR_CREDENTIALS = 'CLEAR_CREDENTIALS',
}

const sendWindowEvents = (key: string, value: string) => {
  localStorageSetItem(key, value);
  localStorageRemoveItem(key);
};

const getUserSession = () => Auth.currentSession();

const getCognitoUser = (organizationId: number) =>
  Auth.currentAuthenticatedUser().then((cognitoUser) => updateCognitoUserOrgIdAttribute(cognitoUser, organizationId));

const getUserPhoneFromCognito = async (): Promise<string> => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const phone = cognitoUser.attributes[PHONE_NUMBER];

  return phone;
};

const updateCognitoUserOrgIdAttribute = async (cognitoUser: CognitoUserWithAttributes, organizationId: number) => {
  const cognitoOrgId = cognitoUser.attributes[CUSTOM_ORG_ID_ATTRIBUTE];

  if (cognitoOrgId) {
    return cognitoUser;
  }

  try {
    await Auth.updateUserAttributes(cognitoUser, { [CUSTOM_ORG_ID_ATTRIBUTE]: organizationId.toString() });
  } catch (error) {
    Sentry.captureEvent({
      level: Sentry.Severity.Warning,
      message: `Error while updating cognito pool organization id ${organizationId} for user ${
        cognitoUser.attributes.email
      }. Error: ${JSON.stringify(error)}}`,
    });
  }

  return cognitoUser;
};

const updateCognitoPoolUserAttribute = async (dynamoUserId: any) => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { attributes } = cognitoUser;
    const userId = attributes['custom:UserId'];

    if (!userId) {
      await Auth.updateUserAttributes(cognitoUser, {
        'custom:UserId': dynamoUserId,
      });
    }
  } catch (err) {
    Sentry.captureEvent({
      level: Sentry.Severity.Warning,
      message: `Error while updating the userID in cognito pool for dynamo user id: ${dynamoUserId} Error: ${JSON.stringify(
        err,
      )}}`,
    });
  }
};

const setupMFAForNewUser = async (user: any) => {
  try {
    await Auth.setPreferredMFA(user, 'SMS');
  } catch (err: any) {
    const email = user?.attributes?.email ?? '';

    Sentry.captureEvent({
      level: Sentry.Severity.Error,
      message: `Unable to update MFA for user: ${email} Err: ${JSON.stringify(err)}}`,
    });
  }
};

const getAccessToken = async () => {
  const userSession = await getUserSession();

  return userSession.getIdToken().getJwtToken();
};

const getRefreshToken = async () => {
  const userSession = await getUserSession();

  return userSession.getRefreshToken().getToken();
};

const renewToken = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const session = await Auth.currentSession();

  // @ts-ignore [CognitoUser type is missing the refreshSession, open issue on AWS]
  await user.refreshSession(session.refreshToken, () => {});

  return getAccessToken();
};

const clearTokens = async () => {
  const newLoginSystemEnabled = isNewLoginSystemEnabled();

  sendWindowEvents(WindowListeningEvent.CLEAR_CREDENTIALS, Date.now().toString());

  if (!newLoginSystemEnabled) {
    await Auth.signOut();
  } else {
    clearTokensV2();
  }
};

// utils for isMTEPSignInSignUpEnabled flow

const getAccessTokenV2 = async () => {
  return CustomStorage.getItem(ACCESS_TOKEN_KEY);
};

const clearTokensV2 = async () => {
  CustomStorage.removeItem(ACCESS_TOKEN_KEY);
  CustomStorage.removeItem(ID_TOKEN_KEY);
};

export {
  clearTokens,
  CustomStorage,
  CUSTOM_ORG_ID_ATTRIBUTE,
  CUSTOM_STORAGE_VAULT,
  getAccessToken,
  getRefreshToken,
  getCognitoUser,
  getUserPhoneFromCognito,
  renewToken,
  sendWindowEvents,
  setupMFAForNewUser,
  updateCognitoPoolUserAttribute,
  WindowListeningEvent,
  getAccessTokenV2,
  clearTokensV2,
  OVERRIDE_MTEP_SIGN_ON_ENABLED_FLAG_KEY,
};
