import { useEffect, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import * as Sentry from '@sentry/react';
import { useAppContext } from 'utils/context';
import useOrganizationId from 'hooks/useOrganizationId';

export interface Flags {
  ENABLE_TRANSACTION_DETAILS_FROM_BFF?: boolean;
  orderReportsUsingOrganizationService?: boolean;
  isMarketBankDataFromOrgService?: boolean;
  isAppConfigsFromOrgService?: boolean;
  isEndpointSsoEnabled?: boolean;
  isDropboxSignClientIdFromEsignService?: boolean;
}

export const useFeatureFlags = (): Flags => {
  const launchDarklyClient = useLDClient();
  const { user } = useAppContext();
  const [flags, setFlags] = useState<Flags>({});
  const organizationId = useOrganizationId();

  const reportErrorToSentry = (error: unknown, orgId: string, email?: string) => {
    Sentry.captureEvent({
      level: Sentry.Severity.Warning,
      message: `Error setting LaunchDarkly feature flags for user ${email}, organization ${orgId}. Error: ${JSON.stringify(
        error,
      )}}`,
    });
  };

  useEffect(() => {
    if (!launchDarklyClient) {
      return;
    }

    const orgId = organizationId.toString();

    if (!user.id || !user.email) {
      launchDarklyClient
        .identify({ kind: 'organization', key: orgId })
        .then((flagSet) => {
          setFlags(flagSet);
        })
        .catch((error) => {
          reportErrorToSentry(error, orgId, user.email);
        });

      return;
    }

    launchDarklyClient
      .identify({
        kind: 'multi',
        user: { key: user.id, email: user.email, firstName: user.firstName, lastName: user.lastName },
        organization: { key: orgId },
      })
      .then((flagSet) => {
        setFlags(flagSet);
      })
      .catch((error) => {
        reportErrorToSentry(error, orgId, user.email);
      });
  }, [user, launchDarklyClient, organizationId]);

  return flags;
};
