import React, { FC, useContext, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { Box, Heading, Stack, Text, Flex, Button } from '@endpoint/blockparty';
import { FormikInputGroup } from 'components/form/FormikBlockparty';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { useInputError } from 'hooks/useInputError';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { isEnabledSsoEmail } from 'utils/singleSignOn';
import { AppContext, AppContextType, CompanyContext, useAppConfigsContext } from 'utils/context';
import { isNewLoginSystemEnabled, signInEmailErrors } from 'Auth/Utilities/helper';
import { useForgotPassword } from 'hooks/amplify/useForgotPassword';
import { useForgotPassword as useForgotPasswordV2 } from 'hooks/auth/useForgotPassword';
import { ErrorCodes, FORGOT_CREDENTIALS_URL } from 'Auth/Utilities/Constant';
import Alerts from 'Auth/Components/Alerts';
import { GenericTrackingEvents, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD } from 'consts/analytics';
import { trackAction } from 'utils/analytics';

interface IForgotPasswordFormValues {
  email: string;
}

export const forgotPasswordValidationSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid email'),
});

const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const forgotPassword = useForgotPassword();
  const forgotPasswordV2 = useForgotPasswordV2();
  const emailError = useInputError(signInEmailErrors);
  const { companyName } = useContext(CompanyContext);
  const { authFields, setAuthFields }: AppContextType = useContext(AppContext);
  const { isEndpointSsoEnabled } = useFeatureFlags();
  const { AWS_COGNITO_SSO_IDENTITY_PROVIDERS, NON_SSO_EMAILS } = useAppConfigsContext();

  const goPrevious = () => {
    setAuthFields({ ...authFields, errorCode: null });
    navigate(FORGOT_CREDENTIALS_URL);
  };

  const goNext = useCallback(
    (values: IForgotPasswordFormValues) => {
      if (isNewLoginSystemEnabled()) {
        forgotPasswordV2(values.email.toLowerCase());
      } else {
        forgotPassword(values.email.toLowerCase());
      }
    },
    [forgotPassword, forgotPasswordV2],
  );

  return (
    <>
      <Box mb="space70" textAlign="center">
        <Heading as="h2" mb="space40" size="fontSize60">
          Forgot password
        </Heading>
        <Text as="p" mb="space60">
          Please enter the email address you use to access {companyName}.
        </Text>{' '}
        <Text as="p">
          If an account exists for this email address, a code will be sent via text message to the phone number
          associated with this account.
        </Text>
      </Box>
      <Stack spacing="space60">
        <Alerts />
        <Formik initialValues={{ email: '' }} validationSchema={forgotPasswordValidationSchema} onSubmit={goNext}>
          {({ isValid, dirty, values }) => (
            <Form>
              <FormikInputGroup
                autoFocus
                data-test-id="email-input"
                error={emailError}
                isRequired
                label="Email Address"
                name="email"
                placeholder="e.g. you@website.com"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const emailIsSso = isEnabledSsoEmail(
                    AWS_COGNITO_SSO_IDENTITY_PROVIDERS,
                    NON_SSO_EMAILS,
                    event.target.value,
                    isEndpointSsoEnabled,
                  );
                  const ssoErrorCode = ErrorCodes.SsoUserPasswordResetException;

                  if (emailIsSso && authFields.errorCode !== ssoErrorCode) {
                    trackAction(GenericTrackingEvents.ERROR_VIEWED, TRACK_GENERIC_ERROR_VIEWED_PAYLOAD);
                    setAuthFields({ ...authFields, errorCode: ErrorCodes.SsoUserPasswordResetException });
                  } else if (!emailIsSso && authFields.errorCode === ssoErrorCode)
                    setAuthFields({ ...authFields, errorCode: null });
                }}
              />
              <Flex flex={1} justifyContent="flex-end" mt="space80" width="100%">
                <Button iconLeft="ArrowChevronLeft" variant="link" onClick={goPrevious}>
                  Back
                </Button>

                <Button
                  data-test-id="next-button"
                  iconRight="ArrowChevronRight"
                  isDisabled={
                    !(isValid && dirty) ||
                    isEnabledSsoEmail(
                      AWS_COGNITO_SSO_IDENTITY_PROVIDERS,
                      NON_SSO_EMAILS,
                      values.email,
                      isEndpointSsoEnabled,
                    )
                  }
                  type="submit"
                >
                  Next
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Stack>
    </>
  );
};

export default ForgotPassword;
